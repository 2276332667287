import { useEffect, useState } from 'react'
import { createLogger } from '@scm/log'
import { selectors } from '@scm/redux-store'

import { ModuleWeatherHomePage } from '@scm/ui-template-home-page'
import { Module } from '@scm/ui-module-section'
import { WeatherHomepageProps } from '@scm/ui-template-weather-homepage'

import { WEATHER_ZIPCODE_STORAGE_KEY } from '~/constants/storage'
import { useStorage } from './useStorage'

import {
  fetchWeatherWidget,
  fetchMarketsDataWidgets,
  HOME_PATHNAME,
  WEATHER_DETAIL_URL,
} from '~/client/fetchData.client'

interface HookHomePageWidgetsProps {
  dataLoader: any
  prevPathname?: string
}

const log = createLogger('useHomePageWidgets')

export function useHomePageWidgets({
  dataLoader,
  prevPathname = HOME_PATHNAME,
}: HookHomePageWidgetsProps) {
  const [zipCode, setZipCode] = useState<string>()
  const [data, setData] = useState<any>(dataLoader)
  const { getStorageValue, setStorageValue } = useStorage({
    setValue: setZipCode,
    storageKey: WEATHER_ZIPCODE_STORAGE_KEY,
  })
  const { disableWeatherWidget } = selectors.useTestAutomation()
  const { isMarketsDivision } = selectors.useConfig()

  const cashGrainBidsZipCode = dataLoader.modules?.find(
    (x: Module) => x?.type === 'module_weather_and_commodity',
  )?.data?.cashGrainBids?.defaultZip

  useEffect(() => {
    const weatherModule = dataLoader.modules.find(
      (x: Module) => x?.type === 'module_weather_and_commodity',
    )

    // prevents re-writing data to default values (due to which weather widget isn't shown)
    // after leaving homepage and returning back before component got unmounted
    if (prevPathname === HOME_PATHNAME) {
      setData(dataLoader)
    }

    if (weatherModule) {
      const localZipcode = getStorageValue(WEATHER_ZIPCODE_STORAGE_KEY)
      const moduleDate = weatherModule.data as ModuleWeatherHomePage

      if (localZipcode && !zipCode) {
        setZipCode(localZipcode)
      } else if (moduleDate && !zipCode) {
        setZipCode(moduleDate.weatherWidget.zipCode)
      }
    }
  }, [dataLoader, getStorageValue, prevPathname, zipCode])

  useEffect(() => {
    const updatedData = { ...data }

    const moduleWeatherAndCommodity = updatedData.modules.find(
      (x: Module) => x?.type === 'module_weather_and_commodity',
    )

    const fetchMarketsData = async () => {
      try {
        const marketsData = await fetchMarketsDataWidgets(cashGrainBidsZipCode)

        moduleWeatherAndCommodity.data.commodityPrices =
          marketsData.commodityPrices?.data || {}

        moduleWeatherAndCommodity.data.cashGrainBids = {
          ...moduleWeatherAndCommodity.data.cashGrainBids,
          name: marketsData.cashGrainBids.items?.[0]?.name ?? '',
          city: marketsData.cashGrainBids.items?.[0]?.city ?? '',
          state: marketsData.cashGrainBids.items?.[0]?.state ?? '',
          bids: marketsData.cashGrainBids.items?.[0]?.bids ?? [],
        }

        setData(updatedData)
      } catch (error) {
        log.error('Error fetching markets data widget:', error)
      }
    }

    if (isMarketsDivision && moduleWeatherAndCommodity) {
      fetchMarketsData()
    }
  }, [])

  useEffect(() => {
    if (zipCode && !disableWeatherWidget) {
      const fetchWeather = async () => {
        try {
          const weatherData = await fetchWeatherWidget(zipCode)
          const newModuleHome = { ...data }
          const moduleWeatherAndCommodity = newModuleHome.modules.find(
            (x: Module) => x?.type === 'module_weather_and_commodity',
          )
          if (moduleWeatherAndCommodity) {
            const moduleWeatherAndCommodityData =
              moduleWeatherAndCommodity.data as WeatherHomepageProps
            moduleWeatherAndCommodityData.weatherWidget = {
              ...weatherData,
              title: moduleWeatherAndCommodityData.weatherWidget?.title ?? '',
              defaultValue: zipCode,
              href: `${WEATHER_DETAIL_URL}${zipCode}`,
              handleChangeLocation: (value: string) => {
                setZipCode(value)
              },
            }
          }
          setData(newModuleHome)
        } catch (error) {
          log.error('Error fetching weather widget:', error)
        }
      }

      fetchWeather()

      setStorageValue({
        storageKey: WEATHER_ZIPCODE_STORAGE_KEY,
        newStorageValue: zipCode,
      })
    }
  }, [zipCode])

  return {
    data,
  }
}
