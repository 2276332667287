import { Fragment } from 'react'
import { Ads } from '@scm/ads'
import { Bem } from '@scm/ui-core'
import { HomePageProps } from './type'
import { ModuleSection } from '@scm/ui-module-section'

const homePage = Bem('HomePage')

export const HomePage = ({ modules, socialShare }: HomePageProps) => {
  if (!modules.length) return null
  return (
    <div data-template="home-page" className={homePage()}>
      <Ads.Ad pos="728_1v" className={homePage('Ad')} />
      <Ads.Ad pos="wallpaper_v" />
      <Ads.Ad pos="oop_v" />
      <Ads.Ad pos="floor_v" />
      <Ads.Ad pos="adhesion_v" />

      <div className={homePage('Wrapper')}>
        {modules.map((data, index) => (
          <Fragment key={index}>
            <ModuleSection
              module={data}
              socialShare={socialShare || []}
              isOnHomepage={true}
              homePageNewsletterClassName={homePage('NewsletterContent')}
            />
            {index === 2 ? (
              <Ads.Ad pos="728_2v" className={homePage('Ad')} />
            ) : null}
            {index === 3 ? (
              <Ads.Ad pos="native_featured_1v" className={homePage('Ad')} />
            ) : null}
            {index === 5 ? (
              <Ads.Ad pos="728_3v" className={homePage('Ad')} />
            ) : null}
            {index === 8 ? (
              <Ads.Ad pos="728_4v" className={homePage('Ad')} />
            ) : null}
            {index === 11 ? (
              <Ads.Ad pos="728_5v" className={homePage('Ad')} />
            ) : null}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
