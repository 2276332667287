import { useLoaderData, useOutletContext } from '@remix-run/react'
import { MetaFunction } from '@remix-run/node'
import { useEffect, useRef } from 'react'
import { Ads } from '@scm/ads'
import { GTM } from '@scm/tag-manager'
import { HomePage } from '@scm/ui-template-home-page'
import { TopFeaturedModuleProps } from '@scm/ui-module-top-featured'
import { BaseContentPreviewProps } from '@scm/ui-module-content-preview'
import { getImagesToPreload } from '@scm/ui-component-image'

import { useHomePageWidgets } from '~/hooks/useHomePageWidgets'
import { LoaderDataType, LoaderType } from '~/routes/.server/_index'
import { HOME_PATHNAME } from '~/constants/home'
import { RootOutletContextData } from '~/types'
import { createMetaTags, PageType } from '~/utils/meta'

export { headers } from '~/components'
export { loader } from '~/routes/.server/_index'

export const meta: MetaFunction<LoaderType> = ({ data }) => {
  if (!data || !data.seo) return []
  const featuredModuleData = data.modules?.find(
    module => module.type === 'module_top_featured',
  )?.data as TopFeaturedModuleProps | undefined

  let featuredImagesUrls: string[] = []
  let imagesToPreload: Record<string, string>[] = []

  if (featuredModuleData) {
    const { variant, features, rectangleAdPos } = featuredModuleData
    const end = variant === 'recent' && !rectangleAdPos ? 5 : 3
    const FeatureList = features ?? []
    featuredImagesUrls = FeatureList.slice(0, end).map(
      (item: BaseContentPreviewProps) => item.thumbnail?.src,
    )
    if (featuredImagesUrls.length) {
      if (variant === 'recent') {
        const preloadMainFeature = getImagesToPreload({
          urls: [featuredImagesUrls[0]],
        })
        const preloadSmallFeatures = getImagesToPreload({
          urls: featuredImagesUrls.slice(1),
          options: { width: 300 },
        })

        imagesToPreload = [...preloadMainFeature, ...preloadSmallFeatures]
      } else {
        imagesToPreload = getImagesToPreload({
          urls: featuredImagesUrls,
        })
      }
    }
  }
  return createMetaTags({
    seo: data.seo,
    contentType: PageType.WEBSITE,
    schema: data.schema,
    imagesToPreload,
  })
}

export default function IndexRoute() {
  const data = useLoaderData<LoaderType>() as LoaderDataType
  const { authenticationStatus } = useOutletContext<RootOutletContextData>()
  const prevPathname = useRef(HOME_PATHNAME)
  const curPathname = useRef(HOME_PATHNAME)

  useEffect(() => {
    if (window.location.pathname !== HOME_PATHNAME) {
      curPathname.current = window.location.pathname
    } else if (curPathname.current !== HOME_PATHNAME) {
      prevPathname.current = curPathname.current
      curPathname.current = window.location.pathname
    }
  })

  const { adUnitPathCode } = Ads.hooks.useShowAds({
    type: 'homepage',
    reg: authenticationStatus,
  })

  GTM.hooks.useLoadEvent({
    pageInfo: {
      adUnitPathCode,
      pageName: 'Home Page',
      destinationURL: GTM.utils.getDestinationURL(),
    },
    category: {
      pageType: 'home page',
    },
    attributes: {
      seo: {
        canonicalURL: data?.seo.canonicalUrl,
        hideFromSearchEngines: data.seo?.noIndex ?? false,
      },
    },
  })

  const { data: dataHomePage } = useHomePageWidgets({
    dataLoader: data,
    prevPathname: prevPathname.current,
  })

  return <HomePage {...dataHomePage} />
}
export { ErrorBoundary } from '../components'
